<template>
  <noscript>
    <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-THG87FK3" height="0" width="0" style="display:none;visibility:hidden">
    </iframe>
  </noscript>
  <div class="content">
    <TheHeader
      v-if="!isCabinet"
    />

    <main class="main">
      <NuxtLoadingIndicator color="#FFC115" />
      <NuxtLayout>
        <NuxtPage />

        <div v-if="apiKeyPopupStore.isOpen">
          <ChangeApiKeyPopup />
        </div>

        <div v-if="passwordPopupStore.isOpen">
          <ChangePasswordPopup />
        </div>

        <div v-if="visibleApiKeyPopupStore.isOpen">
          <ShowApiKeyPopup />
        </div>

        <div v-if="useSuccessPopupStore.isOpen">
          <SuccessPopup />
        </div>
      </NuxtLayout>
    </main>
<!--    <TheFooter />-->

    <div class="custom-toast-container"></div>
  </div>
</template>
<script setup lang="ts">
import TheHeader from '~/components/header/TheHeader.vue';
import TheFooter from '~/components/footer/TheFooter.vue';
import { changeApiKeyPopupStore } from '~/stores/changeApiKey-popup-store';
import { changePasswordPopupStore } from '~/stores/changePassword-popup-store';
import { successPopupStore } from "~/stores/success-popup-store";
import { showApiKeyPopupStore } from '~/stores/showApiKey-popup-store';

useHead({
  meta: [
    { name: 'yandex-verification', content: 'dbb346b1cfb7f203' },
    { name: 'google-site-verification', content: 'C7IqtyJrtOydUtxrcU4SYZT5VOHWhF07O8okJhv0D44' },
  ],
})

import ChangePasswordPopup from "~/components/popups/ChangePasswordPopup.vue";
const ChangeApiKeyPopup = defineAsyncComponent(() => import('~/components/popups/ChangeApiKey.vue'));
const SuccessPopup = defineAsyncComponent(() => import('~/components/popups/Success-popup.vue'));
const ShowApiKeyPopup = defineAsyncComponent(() => import('~/components/popups/ShowApiKeyPopup.vue'));
const apiKeyPopupStore = changeApiKeyPopupStore();
const passwordPopupStore = changePasswordPopupStore();
const useSuccessPopupStore = successPopupStore();
const visibleApiKeyPopupStore = showApiKeyPopupStore();

// Логика отключения хедера в лк пользователя
const $route = useNuxtApp()

const isCabinet = computed(() => {
  return $route?._route.fullPath.includes('cabinet')
})

</script>
<style scoped lang="scss">
.content {
  position: relative;
	 //display: grid;
	 //grid-template-rows: auto 1fr auto;
}

main {
	min-height: calc(100dvh - 60px);
	display: flex;
	flex-direction: column;
	height: 100%;
}

.custom-toast-container {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 10000;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

@supports (min-height:100dvh) {
	main {
		min-height: calc(100dvh - 60px);
	}
}
</style>
